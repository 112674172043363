import {
  boxShadow,
  cardTitle,
  coloredShadow,
  container,
  description,
  hexToRgb,
  mlAuto,
  mrAuto,
  section,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const imgRaised = imagesStyles.imgRaised
const imgRounded = imagesStyles.imgRounded

const publicTastingTipsStyle = (theme) => ({
  container,
  mlAuto,
  mrAuto,
  title: {
    ...title,
    ...textCenter,
    marginTop: "0",
    marginBottom: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0rem",
    },
  },
  titleBottom: {
    ...title,
    textAlign: "left",
    marginTop: "0",
  },
  description,
  textCenter,
  coloredShadow,
  cardTitle,
  section: {
    ...section,
    padding: "80px 0px",
  },
  projects: {
    padding: "80px 0",
  },
  tabSpace: {
    padding: "20px 0 50px 0px",
  },
  cardCategory: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
    marginTop: "10px",
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important",
    marginTop: "10px !important",
  },
  cardDescription: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
  },
  marginTop20: {
    marginTop: "20px",
  },
  card2: {
    textAlign: "center",
  },
  projects3: {
    "& $title": {
      marginBottom: "80px",
      marginTop: "5px",
    },
    "& h6": {
      marginBottom: "5px",
    },
  },
  card4: {
    marginTop: "0",
    marginBottom: "0",
    "& $cardTitle": {
      color: whiteColor,
    },
  },
  cardBody4: {
    paddingTop: "140px",
    paddingBottom: "140px",
  },
  info4: {
    padding: "0",
  },
  projects4: {
    "& hr": {
      margin: "70px auto",
      maxWidth: "970px",
    },
  },
  storyImage: {
    ...boxShadow,
    borderRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      margin: "3rem",
    },
  },
  publicTastingTipsImage: {
    height: "500px",
    ...imgRaised,
    ...imgRounded,
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      marginTop: "3rem",
      marginBottom: "1rem",
    },
  },
  advisoryText: {
    marginTop: "2rem",
  },
})

export default publicTastingTipsStyle
