import {
  faStore,
  faUsers,
  faUtensilsAlt,
} from "@fortawesome/pro-duotone-svg-icons"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import publicTastingTipsStyle from "assets/jss/material-kit-pro-react/views/publicTastingPageSections/publicTastingTipsStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(publicTastingTipsStyle)

export default function PublicTastingTips() {
  const { publicTastingTipsImage1 } = useStaticQuery(graphql`
    query publicTastingTipsImages {
      publicTastingTipsImage1: file(
        relativePath: { eq: "public-tasting/public-tasting-3.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Tips from a Chilimania pro</h2>
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={7} className={classes.mrAuto}>
            <InfoArea
              className={classes.info4}
              title="Tip #1"
              description="If you want to sample a lot of chili, make sure you are on the Chilimania grounds by 2:30 PM at the latest. Lines at the admission gates grow longer as the public tasting nears, so arrive early to beat the rush."
              faIcon={faUsers}
              iconColor="primary"
            />
            <InfoArea
              className={classes.info4}
              title="Tip #2"
              description="Packets containing a spoon, tasting cup, and ballot are distributed throughout the grounds — near the main stage, near the Fulton &amp; Henry Street gate, and in-between as quantities allow."
              faIcon={faUtensilsAlt}
              iconColor="secondary"
            />
            <InfoArea
              className={classes.info4}
              title="Tip #3"
              description="Once the tasting cups are distributed, you’ll need to move quickly through the booths, as the chili generally lasts between 60 and 90 minutes … or less if it is a popular booth!"
              faIcon={faStore}
              iconColor="primary"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.mlAuto}>
            <GatsbyImage
              image={publicTastingTipsImage1.childImageSharp.gatsbyImageData}
              className={classes.publicTastingTipsImage}
              alt="Bloody Mary Picture"
            />
          </GridItem>
        </GridContainer>
        <GridContainer className={classes.advisoryText}>
          <GridItem xs={12} sm={12}>
            <h3 className={classes.titleBottom}>Please be advised…</h3>
            <p className={classes.description}>
              Chilimania does not guarantee that you’ll be able to taste all the
              samples, but if you follow this advice you’ll walk away with a
              full stomach and perhaps a slight case of heartburn!
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
